import React, { useState } from "react";
import ImageWrapper from "./ImageWrapper";

// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";

import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { useEffect } from "react";

function CmsImage({ component, page_id = "" }) {
  var componentStyle =
    typeof component.options.image != "undefined"
      ? {
          backgroundImage: `url(${component.options.image.url})`,
        }
      : {};

  var zoomableComponents = ["image-1667903799"];

  var zoomable = zoomableComponents.includes(component.id);

  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <div className={`h-full image`} id={`${component.id ?? ""}`}>
      <div className={`imageContainer`}>
        {zoomable != "" ? (
          <>
            <div className="cursor-pointer">
              {component.options.image.url != "" ? (
                <ImageWrapper
                  alt={` `}
                  src={component.options.image.url}
                  blur={component.options.image.urlp}
                  width={component.options.image.width}
                  height={component.options.image.height}
                  // priority={false}
                  onClick={() => { setIsZoomed(true) }}
                />
              ) : (
                ""
              )}
            </div>
            {isZoomed ? (
              <Lightbox
                image={component.options.image.url}
                title=""
                onClose={() => { setIsZoomed(false) }}
              ></Lightbox>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {component.options.image.url != "" ? (
              <ImageWrapper
                alt={` `}
                src={component.options.image.url}
                blur={component.options.image.urlp}
                // layout="intrinsic"
                width={component.options.image.width}
                height={component.options.image.height}
                priority={true}
              />
            ) : (
              ""
            )}
          </>
        )}

        {/* if(component.options.image.url != "") {
            if(zoomable) {} ?
    
                <Zoom>
                  <ImageWrapper
                    alt={` `}
                    src={component.options.image.url}
                    blur={component.options.image.urlp}
                    // layout="intrinsic"
                    width={component.options.image.width}
                    height={component.options.image.height}
                    priority={true}
                  />
                </Zoom>
            : 
              ""
            }
        } */}
      </div>
    </div>
  );
}

export default CmsImage;
